import React from "react";
import styled from "styled-components";
import Paragraph from "./Paragraph";
import SmallAnimatedLink from "./SmallAnimatedLink";

const StyledHeader = styled.h2`
    font-size: 3em;
    color: hsl(0, 0%, 20%);
    padding-top: 0.5em;
    padding-bottom: 0.5em;
`;

const FormSectionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6em;
`;

const TextBlockContainer = styled.div``;

const FormContainer = styled.form`
    font-size: 1em;
    justify-self: stretch;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 3fr 1fr;
`;

const SmallInput = styled.input`
    margin: 1em;
    padding: 0.5em;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 2px;
    :focus {
        outline: 1px solid hsl(153, 54%, 43%);
    }
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
        Helvetica, "Muli", Arial, "Lucida Grande", sans-serif;
`;

const MessageInput = styled(SmallInput)`
    grid-column-start: span 2;
    resize: none;
`;

const SubmitButton = styled.button`
    margin: 1em 1em;
    width: 50%;
    padding: 0.5em;
    font-size: 1rem;
    background-color: hsl(153, 54%, 33%);
    color: hsl(0, 0%, 100%);
    border: 0;
    transition-duration: 0.25s;
    border-radius: 3px;
    :hover {
        background-color: hsl(153, 54%, 45%);
    }
    :active {
        border: 0;
        outline: 0;
    }
`;

const LinkInlineContainer = styled.div`
    display: grid;
    justify-items: start;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 4em;
`;

export default function ContactForm() {
    return (
        <>
            <FormSectionContainer>
                <TextBlockContainer>
                    <StyledHeader>Let's Chat</StyledHeader>
                    <Paragraph>
                        Reach out if you have a job for me, if you want to ask
                        me a question, or if you just want to say hi!
                    </Paragraph>
                    <LinkInlineContainer>
                        <SmallAnimatedLink
                            as="a"
                            href="https://github.com/justin-thurman"
                            target="_blank"
                        >
                            GitHub
                        </SmallAnimatedLink>
                        <SmallAnimatedLink
                            as="a"
                            href="https://www.linkedin.com/in/justin-thurman-dev/"
                            target="_blank"
                        >
                            LinkedIn
                        </SmallAnimatedLink>
                        <SmallAnimatedLink
                            as="a"
                            href="https://til.justinthurman.dev/"
                            target="_blank"
                        >
                            Today I Learned
                        </SmallAnimatedLink>
                    </LinkInlineContainer>
                </TextBlockContainer>
                <FormContainer
                    data-netlify="true"
                    subject="Contacted from portfolio"
                    method="POST"
                    name="contact"
                    action="/success"
                >
                    {/* hidden input for netlify form handling must be added manually because this form is rendered via JS */}
                    <input type="hidden" name="form-name" value="contact" />
                    <SmallInput
                        type="text"
                        name="name"
                        placeholder="What's your name?"
                    />
                    <SmallInput
                        type="email"
                        name="address"
                        placeholder="What's your email?"
                    />
                    <MessageInput
                        as="textarea"
                        type="text"
                        name="message"
                        placeholder="What would you like to chat about?"
                    />
                    <SubmitButton type="submit">Send</SubmitButton>
                </FormContainer>
            </FormSectionContainer>
        </>
    );
}
