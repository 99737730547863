import React from "react";
import styled from "styled-components";
import rotatedBullet from "../../static/bullet_icon_rotated.png";
import InlineSpacer from "./InlineSpacer";

const StyledListItem = styled.div`
    display: flex;
    align-items: center;
    grid-column-start: auto;
`;

const StyledListContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 1.5em;
    align-items: center;
    justify-items: start;
    padding: 2em 1em 0em;
`;

const ListItemText = styled.span`
    color: hsl(0, 0%, 20%);
    font-weight: bold;
`;

const BulletImage = () => {
    return <img src={rotatedBullet} alt="list-bullet" height="12" />;
};

const ListItem = ({ children }) => {
    return (
        <StyledListItem>
            <BulletImage />
            <InlineSpacer size={7} axis="horizontal" />
            <ListItemText>{children}</ListItemText>
        </StyledListItem>
    );
};

export default function SkillsList() {
    return (
        <StyledListContainer>
            <ListItem>HTML and CSS</ListItem>
            <ListItem>Javascript and Typescript</ListItem>
            <ListItem>React</ListItem>
            <ListItem>Python</ListItem>
            <ListItem>Django Rest Framework</ListItem>
            <ListItem>Test-Driven Development</ListItem>
            <ListItem>Git</ListItem>
            <ListItem>Docker</ListItem>
        </StyledListContainer>
    );
}

export { ListItem }