import React from "react";
import styled from "styled-components";
import { Container } from "./Layout";

/* 
This component imports Container from Layout because it is rendered OUTSIDE the main Layout on index.js.
This is necessary because Layout implements side margins, but the footer's background color needs to be width: 100%.
This seemed cleaner and less hacky than other methods.
*/

const StyledFooter = styled.div`
    background: hsl(0, 0%, 97%);
    width: 100%;
`;

const FooterLayout = styled(Container)`
    padding-bottom: 3em;
`;

export default function Footer({ children }) {
    return (
        <StyledFooter>
            <FooterLayout>{children}</FooterLayout>
        </StyledFooter>
    );
}
