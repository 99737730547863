import React from "react";
import styled from "styled-components";

const StyledSectionHeader = styled.h2`
    font-size: 3em;
    color: hsl(0, 0%, 20%);
    padding-top: 1.5em;
    padding-bottom: .8em;
`;

export default function SectionHeader({ children }) {
    return <StyledSectionHeader>{children}</StyledSectionHeader>;
}
